import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { Agent, Shift } from "@src/lib/interface";
import moment from "moment-timezone";
import request from "superagent";

import { getAuthHeader } from "../superagent";

export interface AgentShiftResponse extends Shift {
  _id: string;
  agentId: string;
  agentReq: string;
  assignedBy: string;
  charge: number;
  pay: number;
  isAlreadyGrabbed?: string;
  message?: string;
}

/**
 *
 * @returns Is InstantPay allowed for current agent (user)?
 */
export const instantPayStatus = async (): Promise<
  | {
      _id: string;
      isHCPInstantPayProhibited: boolean;
      employmentStatus: string;
    }
  | {
      instantPayStatus: boolean;
    }
  | undefined
> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentprofile/instant-pay-status`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    return undefined;
  }
};

// Line below can be removed after TypeScript 4.1
type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

export const isHCPInstantPayAllowed = (
  status: Awaited<ReturnType<typeof instantPayStatus>>
): boolean => {
  if (status) {
    return "instantPayStatus" in status
      ? Boolean(status.instantPayStatus)
      : !status.isHCPInstantPayProhibited;
  }
  return false;
};

export const fetchAgentProfile = async (): Promise<Agent | undefined> => {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile`)
      .retry(1, (err) => {
        if (!err) {
          return false;
        }
        return true;
      })
      .set(await getAuthHeader());
    return body;
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export const addAgentActivityLog = async (data: object): Promise<void> => {
  try {
    await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentActivityLog/record`)
      .set(await getAuthHeader())
      .send(data);
  } catch (error) {
    logApiFailureEvent(error);
  }
};

/**
 * @deprecated /user/timezone is no longer used
 * Use useAgentProfile and extract the tmz property
 */
export const fetchCurrentTimezone = async (): Promise<string> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/user/timezone`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => {
      moment.tz.setDefault(body.tmz);
      return body.tmz;
    })
    .catch(logApiFailureEvent);
};
