import { IonText } from "@ionic/react";
import { logError } from "@src/appV2/lib/analytics";
import { Facility } from "@src/lib/interface/src";
import moment from "moment";

import { getLateCancellationText, getSentHomeChargesText } from "./utils";

interface ICancellationPayout {
  facility: Facility;
}

const INVALID_VALUE = -1;

const CancellationPayout = ({ facility }: ICancellationPayout) => {
  const lateCancellationPeriod = facility.lateCancellation?.period ?? INVALID_VALUE;
  const lateCancellationFeeHours = facility.lateCancellation?.feeHours ?? INVALID_VALUE;
  const sentHomeChargeHours = facility.sentHomeChargeHours ?? INVALID_VALUE;

  if (
    [lateCancellationFeeHours, lateCancellationPeriod, sentHomeChargeHours].every(
      (value) => value === INVALID_VALUE
    )
  ) {
    const error = new Error(`Invalid cancellation payout policy: ${facility?.name}`);
    logError("Facility Cancelation Payout Error", { error });

    return (
      <div className="cancelation-payout-list">
        <p>
          We could not retrieve the workplace cancellation payout policy at the moment, please try
          again later.
        </p>
      </div>
    );
  }

  const lateCancellationText: string = getLateCancellationText(
    lateCancellationPeriod,
    lateCancellationFeeHours
  );
  const sentHomeChargeHoursText: string = getSentHomeChargesText(sentHomeChargeHours);

  const today = moment().format("MMMM DD, YYYY");
  return (
    <div className="">
      <IonText className="ion-no-padding extra-pay-period">As of {today}</IonText>
      <div className="cancelation-payout-list">
        <ul>
          {lateCancellationText.length > 0 && (
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: lateCancellationText,
                }}
              />
            </li>
          )}
          {sentHomeChargeHoursText.length > 0 && (
            <li>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${sentHomeChargeHoursText}`,
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export { CancellationPayout };
